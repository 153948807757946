* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: Determination;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 25vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For Small Screens */
.gameboard {
  margin: 15vh auto;
  height: 85vw;
  width: 85vw;
  background-color: blue;
  z-index: -1;
}

/* For Large Screens */
@media only screen and (min-width: 600px) {
  .gameboard {
    margin: 10vh auto;
    height: 80vh;
    width: 80vw;
    background-color: rgba(4, 3, 50);
    z-index: -1;
    border: 5px;
    border-color: black;
    border-style: solid;
    display: inline-block;
  }
}

.SignupForm {
  scale: 125%;
  padding-top: 1%;
  z-index: 1;
}

.LoginForm {
  scale: 125%;
  z-index: 1;
}

#continue-table-div{
  display: flex;
  justify-content: center;
}

.ContinueMenu {
  border-spacing: 15px;
}

.CurrentLoginMenu {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  border-spacing: 30px;
  color: white;
}

.LeaderBoardTable {
  float: right;
  font-size: larger;
  border-spacing: 15px;
  color: white;
  height: 55vh;
}

@font-face {
  font-family: Determination;
  src: url("../public/Determination.ttf");
}

@font-face {
  font-family: Barcade;
  src: url("../public/Barcade.otf");
}

.MainTitle {
  font-family: Barcade;
  opacity: 75%;
  color: rgb(69, 115, 223);
  margin: 0 auto;
  margin-top: 25vh;
  scale: 250%;
}

.MenuButton {
  font-family: Determination;
}

.playMusicButton {
  font-family: Determination;
  scale: 125%;
  z-index: 1;
  margin: 0 auto;
  margin-top: 2vh;
}

.play-music-button-general {
    font-family: Determination;
    scale: 125%;
    z-index: 1;
    position: absolute;
    right: 100px;
    bottom: 100px;
    width: 100px;
}
  

input {
  font-family: Determination;
}

.CurrentUserData {
  margin-left: 0 auto;
  margin-right: 0 auto;
  font-size: larger;
}

.CurrentUserTitle {
  margin-left: 0 auto;
  color: white;
}

.LeaderBoardTitle,
.LeaderBoardTable {
  font-weight: bold;
}
#player-image {
  max-width: 100%;
  max-height: 100%;
}
.ship:focus {
  outline: 0;
}
.alien-image {
  max-width: 100%;
  max-height: 100%;
}
.BoardTitle {
  position: fixed;
  font-size: larger;
  left: 45%;
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000px 0;
  }
}

@keyframes move-twinkle-back {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}

#scoreboard {
  left: 5vh;
  top: 0vh;
  color: white;
  z-index: 1;
  position: absolute;
}

.ship {
  z-index: 1;
}

.stars,
.twinkling {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.stars {
  background: #000 url(../public/stars.png) repeat top center;
  z-index: 0;
}

.twinkling {
  background: transparent url(../public/twinkling.png) repeat top center;
  z-index: 0;
  animation: move-twinkle-back 200s linear infinite;
}

.starsHome,
.twinklingHome {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.starsHome {
  background: #000 url(../public/stars.png) repeat top center;
  z-index: -1;
}

.twinklingHome {
  background: transparent url(../public/twinkling.png) repeat top center;
  z-index: -1;
  animation: move-twinkle-back 200s linear infinite;
}

#PlanetA {
  opacity: 80%;
  z-index: 1;
  position: absolute;
  width: 150px;
  right: 10vh;
  bottom: 30vh;
}

#PlanetB {
  opacity: 80%;
  z-index: 1;
  position: absolute;
  width: 150px;
  left: 10vh;
  top: 25vh;
}

#PlanetC {
  opacity: 80%;
  z-index: 1;
  position: absolute;
  width: 150px;
  right: 35vh;
}
.player-image{
  max-width: 100%;
  max-height: 100%;
}
.player-container{
  display: flex;
  height: auto;
  width: 25vh;
  cursor: pointer;
}
#two-player-container{
  display: flex;
  justify-content: space-evenly;
}

.chosen-player-container{
  display: flex;
  height: auto;
  width: 25vh;
}
.player-container:hover{
  animation: spring 300ms ease-out;
}
.player-container:active{
  transform: translateY(4px);
}

button {
  cursor: pointer;
}

.attribution {
  z-index: 999;
  color: white;
  position: absolute;
  bottom: 10px;
}

.controls-text {
    color: white;
    margin: 10px;
}
.controls-container {
    color: white;
    border-color: white;
    border-style:double;
    padding: 20px;
}
.controls-title {
    margin: 5px;
    font-style: italic;
}

@keyframes spring {
  15% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1.2, 1.1);
  }
  40% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(0.95, 0.95);
  }
  75% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1.05, 1);
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1, 1);
  }
}
